<template>
  <div class="contentBox">
    <div class="container" id="container" @scroll="showScroll">
      <img class="bg" src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/new.jpg" alt="使命先锋"/>
      <div class="footer">海南瀚动网络科技有限公司</div>
    </div>
    <div class="bottomBox"></div>
    <Transition>
      <div class="bottom1" v-show="!isTop">
        <div class="left">
          <div class="icon">
            <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/icon192.png" alt="使命先锋"/>
          </div>
          <div class="gameDetail">
            <div class="gameDetailTitle" style="font-size: 0.36rem;">使命先锋</div>
            <div class="gameDetailScore">
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/score.png" alt="使命先锋"/>
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/score.png" alt="使命先锋"/>
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/score.png" alt="使命先锋"/>
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/score.png" alt="使命先锋"/>
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/noScore.png" alt="使命先锋"/>
              <span>4.0</span>
            </div>
            <div class="gameDetailtext">地下城类正版手游</div>
          </div>
        </div>
        <div class="downLoadBtn" @click="downLoad">立即下载</div>
      </div>
    </Transition>
    <Transition>
      <div class="bottom2" v-show="isTop">
        <div class="left">
          <div class="icon">
            <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/icon192.png" alt="使命先锋"/>
          </div>
          <div class="gameDetail">
            <div class="gameDetailTitle">使命先锋</div>
            <div class="gameDetailScore">
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/score.png" alt="使命先锋"/>
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/score.png" alt="使命先锋"/>
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/score.png" alt="使命先锋"/>
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/score.png" alt="使命先锋"/>
              <img src="https://smxfcdn.oss-cn-shanghai.aliyuncs.com/imgs/noScore.png" alt="使命先锋"/>
              <span>4.0</span>
            </div>
            <div class="gameDetailtext">地下城类正版手游</div>
          </div>
        </div>
        <div class="downLoadBtn" @click="downLoad">立即下载</div>
        <div class="explain">
          <div class="explainText1">
            <span>版本105.98.8ad15422</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <span>更新于2024/03/22</span>
          </div>
          <div class="explainText2">
            <span>开发者：上海蔓株网络科技有限公司</span
            >&nbsp;&nbsp;&nbsp;&nbsp;
            <span @click="skip">隐私</span>&nbsp;&nbsp;&nbsp;&nbsp;
            <span @click="skipAuth">权限</span>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      isTop: true,
    }
  },
  methods: {
    showScroll() {
      let st = document.getElementById('container').scrollTop
      if (st === 0) {
        this.isTop = true
        return
      }
      this.isTop = false
    },
    skip() {
      window.location.href = '../html/privacy.html'
    },
    skipAuth() {
      window.location.href = '../html/auth.html'
    },
    downLoad() {
      const androidUrl = 'http://smxf.download.yilaicheng.cn/game/11113/202403/SMXF_version_smxfd_20240308145206.apk'
      const iosUrl = 'http://smxf.sdk.yilaicheng.cn/url/YI3OgQYR.apk'
      const ua = navigator.userAgent.toLowerCase()
      const isIos = ua.indexOf('iphone') != -1 || ua.indexOf('ipad') != -1
      const url = isIos?iosUrl:androidUrl
      window.location.href = url
    },
  },
}
</script>

<style lang="less" scoped>
.contentBox{
  position: relative;
  width: 100vw;
  height: 100vh;
}
.container {
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: scroll;
}
.carousel{
  margin-top: -256px;
}
.carousel-control{
  opacity: 1;
  width: 8%;
}
.carousel-indicators li{
  width: 6px;
  height: 6px;
  background: rgba(255,255,255,0.15);
  border-radius: 0;
  border:none;
  vertical-align: middle;
  margin-left:4px;
  margin-right: 4px;
  margin-top: 40px;
  border-radius: 50%;
}
.carousel-indicators{
  height: 4px;
  bottom: 20px;
}
.carousel-indicators .active{
  width: 6px;
  height: 6px;
  background: #ffffff;
}
.carousel-control.left,
.carousel-control.right{
  background-image:none;
  z-index: 999;
}
.glyphicon{
  line-height: 30px;
  top: 40%;
}
.glyphicon-chevron-left:before,
.glyphicon-chevron-right:before{
  content: '';
}
.carousel-inner{
  .item{
    margin-left: 6%;
    width: 88%;
  }
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}
.carousel-inner .carousel-item {
  transition-duration: 1s;
}
.bg {
  display: inline-block;
  width: 108.7%;
  margin-left: -4.35%;
  height: auto;
}
.footer {
  font-size: 0.3733rem;
  text-align: center;
  padding: 0.3333rem 0 3.2533rem 0;
}
.bottom1 {
  position: absolute;
  z-index: 99;
  bottom: 0.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: 9.2rem;
  height: 2.5333rem;
  border: 1px solid #eee;
  box-shadow: 0 0.4rem 1.04rem 0 rgb(0 0 0 / 10%);
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
}
.bottom1 .left {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
}
.bottom1 .icon {
  width: 1.52rem;
  height: 1.52rem;
  margin-right: 0.2133rem;
}
.bottom1 .icon img {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.bottom1 .gameDetailTitle {
  font-size: 0.48rem;
  font-weight: bold;
  color: #000;
}
.bottom1 .gameDetailScore {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 2.9333rem;
  padding: 0.16rem 0;
  color: rgb(102, 102, 102);
}
.bottom1 .gameDetailScore img {
  display: inline-block;
  width: 0.3733rem;
  height: 0.3733rem;
}
.bottom1 .gameDetailtext {
  color: rgb(153, 153, 153);
}
.bottom1 .downLoadBtn {
  width: 2.5333rem;
  height: 1.28rem;
  background-color: rgb(230, 50, 46);
  color: #fff;
  border-radius: 0.64rem;
  line-height: 1.28rem;
  text-align: center;
  font-size: 0.4267rem;
  font-weight: bold;
  animation: mymove 5s infinite;
  -webkit-animation: mymove 5s infinite; /*Safari and Chrome*/
  animation-direction: alternate; /*轮流反向播放动画。*/
  animation-timing-function: ease-in-out; /*动画的速度曲线*/
  /* Safari 和 Chrome */
  -webkit-animation: mymove 5s infinite;
  -webkit-animation-direction: alternate; /*轮流反向播放动画。*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
}
@keyframes mymove {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}

@-webkit-keyframes mymove {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.2);
  }
  50% {
    transform: scale(1);
  }
  75% {
    transform: scale(1.2);
  }
}
.bottomBox {
  position: absolute;
  width: 100vw;
  height: 2.1867rem;
  bottom: 0;
  left: 0;
  background-color: #fff;
}
.bottom2 {
  position: absolute;
  z-index: 99;
  bottom: 0.4rem;
  left: 50%;
  transform: translateX(-50%);
  width: 9.2rem;
  height: 5.9467rem;
  border: 1px solid #eee;
  box-shadow: 0 0.4rem 1.04rem 0 rgb(0 0 0 / 10%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  background-color: #fff;
}
.bottom2 .left {
  position: relative;
  display: inline-flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}
.bottom2 .icon {
  position: absolute;
  bottom: 2.5333rem;
  width: 1.52rem;
  height: 1.52rem;
}
.bottom2 .icon img {
  display: inline-block;
  width: 100%;
  height: 100%;
}
.bottom2 .gameDetail {
  height: 3.2rem;
}
.bottom2 .gameDetailTitle {
  margin-top: 1.0667rem;
  font-size: 0.48rem;
  font-weight: bold;
  color: #000;
}
.bottom2 .gameDetailScore {
  display: inline-flex;
  justify-content: space-around;
  align-items: center;
  width: 2.9333rem;
  padding: 0.16rem 0;
  color: rgb(102, 102, 102);
}
.bottom2 .gameDetailScore img {
  display: inline-block;
  width: 0.3733rem;
  height: 0.3733rem;
}
.bottom2 .gameDetailtext {
  color: rgb(153, 153, 153);
}
.bottom2 .downLoadBtn {
  width: 7.4667rem;
  height: 1.28rem;
  background-color: rgb(230, 50, 46);
  color: #fff;
  border-radius: 0.64rem;
  line-height: 1.28rem;
  text-align: center;
  font-size: 0.4267rem;
  font-weight: bold;
  animation: mymove2 5s infinite;
  -webkit-animation: mymove2 5s infinite; /*Safari and Chrome*/
  animation-direction: alternate; /*轮流反向播放动画。*/
  animation-timing-function: ease-in-out; /*动画的速度曲线*/
  /* Safari 和 Chrome */
  -webkit-animation: mymove2 5s infinite;
  -webkit-animation-direction: alternate; /*轮流反向播放动画。*/
  -webkit-animation-timing-function: ease-in-out; /*动画的速度曲线*/
}
@keyframes mymove2 {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
  }
}

@-webkit-keyframes mymove {
  0% {
    transform: scale(0.9);
  }
  25% {
    transform: scale(1.1);
  }
  50% {
    transform: scale(0.9);
  }
  75% {
    transform: scale(1.1);
  }
}
.v-enter-active,
.v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from,
.v-leave-to {
  opacity: 0;
}
.explain {
  color: rgb(153, 153, 153);
  text-align: center;
}
.explainText2 {
  padding-top: 4px;
}
</style>
